import { Link } from 'gatsby';
import React from 'react';
import IconArrow from '~/components/atoms/IconArrow';
import FadeInWhenVisible from '~/components/FadeInWhenVisible';

export default function Career({
  careerItem: {
    careerItem: { department, city, country },
  },
  title,
  uri,
}) {
  return (
    <Link to={uri} className="group">
      <FadeInWhenVisible className="border-b border-b-navy py-8 flex flex-col sm:flex-row sm:items-center">
        <div className="items-center mb-4 sm:mb-0">
          {title && <h4 className="text-h4 mb-2">{title}</h4>}
          <div className="flex items-center space-x-5">
            {department && <span className="">{department}</span>}
            <div className="w-2 h-2 bg-navy bg-opacity-30 rounded-full"></div>
            {(city?.length > 0 || country?.length > 0) && (
              <span className="">
                {city}
                {country.length > 0 ? ', ' + country : ''}
              </span>
            )}
          </div>
        </div>
        <button className="sm:ml-auto text-h6 flex items-center">
          <span className="group-hover:-translate-x-4 duration-200 transition-transform ease-in-out">Apply</span> <IconArrow className="transform -rotate-90 text-blue inline-block ml-4 -mt-1" />
        </button>
      </FadeInWhenVisible>
    </Link>
  );
}
