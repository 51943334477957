import { useStaticQuery, graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';
import Career from '~/components/molecules/Career';
import FadeInWhenVisible from '~/components/FadeInWhenVisible';

export default function Careers({key, index, ...props}) {
  const section = props.careers;

  const { allWpCareerItem } = useStaticQuery(graphql`
    query {
      allWpCareerItem {
        nodes {
          id
          title
          uri
          careerItem {
            careerItem {
              department
              city
              country
            }
          }
        }
      }
    }
  `);

  const [careers, setCareers] = useState(allWpCareerItem.nodes);
  const [department, setDepartment] = useState('');
  const [country, setCountry] = useState('');

  useEffect(() => {

    const filteredCareers = allWpCareerItem.nodes.filter((c) => {
      const departmentMatch = (department.length) ? department === c.careerItem.careerItem.department : true;
      const countryMatch = (country.length) ? country === c.careerItem.careerItem.country : true;
      return departmentMatch && countryMatch;
    });

    setCareers(filteredCareers);
  }, [department, country]);

  return (
    <section className="my-20 md:my-36" id={section.fieldGroupName + index} >
      <div className="container">
        <header className="text-left mb-10 md:mb-16">
          <FadeInWhenVisible>
            {section.subheading && <h6 className="text-h6 text-blue mb-4">{section.subheading}</h6>}
            <div className="text-h2 col-span-12 mb-2 flex">
              Show me{' '} 
              <div className="relative border-b-4 border-b-yellow ml-4 pr-8 heading-select">
                {department ? department : 'All departments'}
                <select
                  className="absolute inset-0 z-10 block w-full text-base bg-transparent pr-8 cursor-pointer heading-select outline-none opacity-0"
                  onChange={(e) => setDepartment(e.target.value)}>
                  <option value="">All departments</option>
                  {allWpCareerItem.nodes
                    .map((c) => c.careerItem.careerItem.department)
                    .map((c) => (
                      <option key={c} value={c} dangerouslySetInnerHTML={{ __html: c }} />
                    ))}
                </select>
              </div>
            </div>
            <div className="text-h2 col-span-12 mb-2 flex">
              Jobs in{' '} 
              <div className="relative border-b-4 border-b-yellow ml-4 pr-8 heading-select">
                {country ? country : 'All locations'}
                <select
                  className="absolute inset-0 z-10 block w-full text-base bg-transparent pr-8 cursor-pointer heading-select outline-none opacity-0"
                  onChange={(e) => setCountry(e.target.value)}>
                  <option value="">All locations</option>
                  {allWpCareerItem.nodes
                    .map((c) => c.careerItem.careerItem.country)
                    .map((c, index) => (
                        index < 1
                        ?
                        <option key={c} value={c} dangerouslySetInnerHTML={{ __html: c }} />
                        :
                        ''
                    ))}
                </select>
              </div>
            </div>
          </FadeInWhenVisible>
        </header>

        <div className="">
          {careers.map((item, i) => (
            <Career key={`career${i}`} {...item} />
          ))}
        </div>
      </div>
    </section>
  );
}
